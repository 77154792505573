import React from 'react';

export function SayingAboutUsCard({item}: { item: any }) {
  return (
    <div className="relative saying-card mr-0 mb-6 xl:mr-8 xl:mb-0">
      <div className="absolute bottom-0 flex items-center left-0 top-0 z-20">
        <div className="saying-avatar">
          <img src={item.avatarUrl}/>
        </div>
      </div>
      <div className="bg-card-black ml-9 xl:ml-14 py-8 pl-20 xl:pl-24 pr-10 rounded-b-4xl rounded-tl-4xl relative">
        <img className="absolute left-8 top-4" src="/assets/images/quote.svg" />
        <div className="text-white text-xl font-bold relative">{item.name}</div>
        <div className="text-yellow pb-4 relative">{item.title}</div>
        <div className="text-xs xl:text-base text-gray relative">{item.description}</div>
      </div>
    </div>
  )
}