import React from 'react';

export function ConnectSocial() {
  return (
    <div className="grid gap-4">
      <label className="font-bold text-gradient text-2xl">Connect social</label>
      <div className="grid grid-cols-3 gap-4">
        <a href="#" className="btn-black"><img alt="telegram" src="/assets/images/telegram.svg" /> Telegram</a>
        <a href="#" className="btn-black"><img alt="youtube" src="/assets/images/youtube.svg" /> Youtube</a>
        <a href="#" className="btn-black"><img alt="instagram" src="/assets/images/instagram.svg" /> Instagram</a>
        <a href="#" className="btn-black"><img alt="wechat" src="/assets/images/wechat.svg" /> WeChat</a>
        <a href="#" className="btn-black"><img alt="linkedin" src="/assets/images/linkedin.svg" /> Linkedin</a>
        <a href="#" className="btn-black"><img alt="twitter" src="/assets/images/twitter.svg" /> Twitter</a>
        <a href="#" className="btn-black"><img alt="facebook" src="/assets/images/facebook.svg" /> Facebook</a>
        <a href="#" className="btn-black"><img alt="zalo" src="/assets/images/zalo.svg" /> Zalo</a>
      </div>
    </div>
  )
}