import * as React from 'react';
import { TopHero } from '@components/Home/TopHero';
import { TopStore } from '@components/Home/TopStore';
import { GetStarted } from "@components/Home/GetStarted";
import { Business } from "@components/Home/Business";
import { Showcase } from "@components/Home/Showcase";
import { SayingAboutUs } from "@components/Home/SayingAboutUs";
import { Resources } from "@components/Home/Resources";

export function Home() {
  return (
    <>
      <TopHero />
      <TopStore />
      <GetStarted />
      <Business />
      <Showcase />
      <SayingAboutUs />
      <Resources />
    </>
  )
}
