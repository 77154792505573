import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';
import { Header } from '@partials/Header';
import { Footer } from '@partials/Footer';
import { Home } from '@pages/Home';

export function App() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden relative">
      <Header />

      <main className="bg-light-black flex-grow">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </main>

      <Footer />
    </div>
  );
}
