import React, { useState } from "react";
import classnames from "classnames";

const categories = ["Digital Art", "Anime/Animation", "E-Sport", "Music", "Film/TV", "Gaming"];

export function Business() {
  const [activeCate, setActiveCate] = useState("Digital Art");

  const onChangeCate = (cate: string) => () => setActiveCate(cate);

  return (
    <section className="max-w-8xl mx-auto mb-28 lg:mb-0 py-12 grid grid-flow-row grid-cols-12 gap-7">
      <div className="col-span-12 px-5 pb-8 lg:pb-0 lg:col-span-5 lg:pr-8">
        <p className="text-yellow text-base pb-2 lg:pb-5">Gamify for Businesses</p>
        <p className="text-white text-2xl lg:text-5xl font-bold pb-5 leading-normal">Everything you need, all in one place</p>
        <div className="text-gray text-sm lg:text-base leading-6 lg:leading-8">
          Looking to utilize NFTs? Great!
          <br />
          <br />
          You'll need a platform to create them, a marketplace to list them, and a wallet to help users trade and manage them. <br />
          Previously, you couldn't do this all with a single line of products—but now you can.
          <br />
          Enjin provides the end-to-end solution needed to launch NFT projects that help you generate new revenue streams and accelerate your company's growth.
        </div>
      </div>
      <div className="col-span-12 pl-5 lg:px-5 lg:col-span-7 relative">
        <div className="lg:block business-circle" />
        <div className="business-circle-gradient" />
        <div className="business-container">
          <div className="flex overflow-auto lg:grid lg:grid-flow-col gap-2">
            {categories.map((value) => (
              <button
                key={value}
                className={classnames("btn whitespace-nowrap", {
                  "btn-primary": activeCate === value
                })}
                onClick={onChangeCate(value)}
              >
                <span className="px-4 py-1.5 lg:p-0">{value}</span>
              </button>
            ))}
          </div>
          <div className="text-white text-xl lg:text-3xl leading-7 font-bold pt-10 pb-6">
            Forge games that allow players to <br className="hidden lg:inline" />
            create real-world value while <br className="hidden lg:inline" />
            having fun
          </div>
          <div className="text-sm lg:text-lg text-yellow leading-6 lg:leading-9 pb-2">Featured Article</div>
          <div className="text-sm lg:text-lg text-white leading-6 lg:leading-9 pb-12">Play to Earn: Asset Scarcity & What it Means for You</div>
          <div className="flex items-center">
            <div className="gradient p-0.5 rounded-xl">
              <button className="bg-light-black px-6 py-1 rounded-xl xl:py-2.5">
                <span className="text-gradient uppercase font-bold">See More</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
