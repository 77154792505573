import React from "react";

const images = ["bitcoin.png", "crystals.png", "war.png", "pandemic.png", "min-mins.png"];
const tripleImages: string[] = Array(3)
  .fill(0)
  .reduce((total) => total.concat(...images), []);

export function TopStore() {
  return (
    <>
      <section className="max-w-8xl px-5 mx-auto py-8 xl:py-12">
        <div className="flex flex-col md:flex-row justify-between items-center mb-24 xl:mb-36">
          <div className="max-w-lg mb-8 md:mb-0 md:mr-16 lg:mr-24 xl:mr-36">
            <p className="text-yellow text-xs md:text-lg mb-2 md:mb-6">Gamify store</p>
            <p className="text-white text-2xl md:text-5xl font-semibold mb-4 md:mb-8">What are NFTs?</p>
            <p className="text-gray text-sm leading-6 md:text-base md:leading-8">
              NFTs are unique digital assets created on the blockchain. They can be everything from gaming items and digital art, to sports collectibles and real-world assets.
            </p>
          </div>
          <div className="flex-initial">
            <img className="hidden md:block" src="/assets/images/nft.svg" alt="What are NFTs?" />
            <img className="md:hidden" src="/assets/images/nft-mobile.svg" alt="What are NFTs?" />
          </div>
        </div>
      </section>
      <section className="mx-auto relative overflow-x-hidden p-0 md:px-4 md:py-8 xl:py-12 ">
        <div className="shadow-left absolute z-20 left-0 top-0 bottom-0 right-1/2" />
        <div className="mb-6">
          <div className="flex flex-nowrap translate-left-1 mb-4 md:mb-6">
            {tripleImages.map((value) => (
              <img key={value} className="w-32 md:w-auto rounded-sm md:rounded-2xl mr-4 md:mr-6" src={`/assets/images/games/${value}`} alt={value} />
            ))}
          </div>
          <div className="flex flex-nowrap translate-left-2">
            {tripleImages.map((value) => (
              <img key={value} className="w-32 md:w-auto rounded-sm md:rounded-2xl mr-4 md:mr-6" src={`/assets/images/games/${value}`} alt={value} />
            ))}
          </div>
        </div>
        <div className="shadow-right absolute z-20 right-0 top-0 bottom-0 left-1/2" />
      </section>
    </>
  );
}
