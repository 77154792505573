import React from 'react';

const resources = [
  { imageUrl: '/assets/images/resource-1.png', date: 'December 7, 2021', name: 'Hololoot: Augmented Reality NFT Platform Joins Enjin' },
  { imageUrl: '/assets/images/resource-2.png', date: 'December 7, 2021', name: 'Hololoot: Augmented Reality NFT Platform Joins Enjin' },
  { imageUrl: '/assets/images/resource-3.png', date: 'December 7, 2021', name: 'Hololoot: Augmented Reality NFT Platform Joins Enjin' },
]

export function Resources() {
  return (
    <section className="max-w-8xl px-5 mx-auto py-8 xl:py-12">
      <div className="text-white font-bold text-5xl mb-8">Resources</div>
      <div className="max-w-8xl px-5 mx-auto overflow-auto">
        <div className="flex w-max xl:w-auto xl:grid xl:grid-cols-12 xl:gap-6 pb-10 xl:pb-20">
          {resources.map((item, i) => (
            <div key={i} className="xl:col-span-4 w-80 xl:w-auto mr-6 xl:mr-0">
              <img src={item.imageUrl} className="rounded-4xl" />
              <div className="text-yellow font-bold py-4">{item.date}</div>
              <div className="text-white text-xl font-bold">{item.name}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 xl:col-span-5 flex items-center justify-between py-6 px-8 rounded-2xl explore-individuals">
          <div>
            <div className="text-white">Explore Enjin for</div>
            <div className="text-3xl text-white font-bold">Individuals</div>
          </div>
          <img src="/assets/images/arrow-right.svg" />
        </div>
        <div className="col-span-12 xl:col-span-7 grid grid-cols-1 xl:grid-cols-2 gap-6">
          <div className="py-6 px-8 rounded-2xl explore-business">
            <div className="text-white">Explore Enjin for</div>
            <div className="text-3xl text-white font-bold">Businesses</div>
          </div>
          <div className="py-6 px-8 rounded-2xl explore-business">
            <div className="text-white">Explore Enjin for</div>
            <div className="text-3xl text-white font-bold">Developers</div>
          </div>
        </div>
      </div>
    </section>
  )
}
