import React, { useEffect, useState } from "react";
import classnames from "classnames";

export function Header() {
  const [top, setTop] = useState(true);

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <>
      <header className={
        classnames('fixed w-full z-30 bg-transparent transition duration-300 ease-in-out', {
          'bg-light-black xl:opacity-90 blur shadow-lg': !top
        })}>
        <div className="max-w-screen-2xl mx-auto px-5">
          <div className="flex items-center justify-between h-16 xl:h-20">
            <div className="flex">
              <img className="w-28 xl:w-auto" alt="Gamify" src="/assets/images/logo.svg" />
            </div>
            <div>
              <button className="btn btn-primary uppercase font-bold border-white text-xs md:text-base mr-2 xl:mr-4">
                <span className='px-2 md:px-10 py-0.5'>Go to shop</span>
              </button>
              <select className="uppercase font-bold bg-transparent border-white text-xs md:text-base">
                <option value={'en'} label="English"></option>
              </select>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
